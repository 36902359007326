import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';

import SideNavPub from '../../components/SideNavPub';
import { Styles, styles } from './ZeroGraspContainer.styles';

class ZeroGraspContainer extends React.Component<Styles> {

  componentDidMount = () => {
    document.title = 'ZeroGrasp: Zero-Shot Shape Reconstruction Enabled Robotic Grasping';
  }

  render = () => {
    const {
      classes,
    } = this.props;

    const citation = `@InProceedings{Iwase_CVPR_2025,
  author = {Iwase, Shun and, Irshad, Zubair and Liu, Katherine and Guizilini, Vitor and Lee, Robert and Ikeda, Takuya and Amma, Ayako and Nishiwaki, Koichi and Kitani, Kris and Ambruș, Rareș and Zakharov, Sergey},
  title = {ZeroGrasp: Zero-Shot Shape Reconstruction Enabled Robotic Grasping},
  booktitle = {CVPR},
  year = {2025}
}`

    const onClickCopyButton = () => {
      navigator.clipboard.writeText(citation);
    }

    return (
      <Paper elevation={0}>
        <Grid item xs container className={classes.paperContainer}>
          <Grid item md={12} lg={12} xl={12} className={classes.sideNavContainer}>
            <SideNavPub />
          </Grid>
          <Grid item md={8} lg={6} xl={5} className={classes.paperContextWrapper}>
            <div className={classes.paperContext}>
              <Typography className={classes.paperTitle}>
                ZeroGrasp: Zero-Shot Shape Reconstruction Enabled Robotic Grasping
              </Typography>
              <Typography className={classes.authorName}>
                <Link className={classes.authorLink} href="https://www.sh8.io" target="_blank">Shun Iwase<sup>1,2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://zubairirshad.com/" target="_blank">Muhammad Zubair Irshad<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://www.thekatherineliu.com/" target="_blank">Katherine Liu<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://vitorguizilini.weebly.com/" target="_blank">Vitor Guizilini<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://www.linkedin.com/in/robert-lee-a8a98922b" target="_blank">Robert Lee<sup>3</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://www.linkedin.com/in/takuya-ikeda-a66132190" target="_blank">Takuya Ikeda<sup>3</sup></Link>&emsp;
                <span className={classes.authorLink}>Amma Ayako<sup>3</sup></span>&emsp;
                <Link className={classes.authorLink} href="https://www.linkedin.com/in/knishiwaki/" target="_blank">Koichi Nishiwaki<sup>3</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://kriskitani.github.io/" target="_blank">Kris Kitani<sup>1</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://www.tri.global/about-us/dr-rares-ambrus" target="_blank">Rareș Ambruș<sup>2</sup></Link>&emsp;
                <Link className={classes.authorLink} href="https://zakharos.github.io/" target="_blank">Sergey Zakharov<sup>2</sup></Link>&emsp;
              </Typography>
              <Typography className={classes.affiliationName}>
                <span className={classes.affiliation}><sup>1</sup>Carnegie Mellon University</span>
                <span className={classes.affiliation}><sup>2</sup>Toyota Research Institute</span>
                <span className={classes.affiliation}><sup>3</sup>Woven by Toyota</span>
              </Typography>
              <Typography className={classes.conferenceName}>
                <i>CVPR 2025</i>
              </Typography>
              <Typography className={classes.content}>
              </Typography>
              <Grid item>
                <img
                  className={classes.imageStyle}
                  alt='high_overview.img'
                  src={require('../../assets/zerograsp/teaser-web.png')}
                />
              </Grid>
              <Typography className={classes.content}> 
                Overview: We propose ZeroGrasp, a framework for simultaneous reconstruction and grasp pose prediction of multiple unseen objects from a single RGB-D image in near real-time. ZeroGrasp achieves the state-of-the-art results across all splits on GraspNet-1B benchmark and demonstrates strong generalization and robustoness in real-world scenarios.
              </Typography>

              <Typography className={classes.subtitle}>
                Abstract
              </Typography>
              <Divider className={classes.dividerStyle} />
              <Grid item>
                <Typography className={classes.content}> 
                  Robotic grasping is a cornerstone capability of embodied systems. Many methods directly output grasps from partial information without modeling the geometry of the scene, leading to suboptimal motion and even collisions. To address these issues, we introduce ZeroGrasp, a novel framework that simultaneously performs 3D reconstruction and grasp pose prediction in near real-time. A key insight of our method is that occlusion reasoning and modeling the spatial relationships between objects is beneficial for both accurate reconstruction and grasping. We couple our method with a novel large-scale synthetic dataset, which is an order of magnitude larger than existing datasets and comprises 1M photo-realistic images, high-resolution 3D reconstructions and 11.3B physically-valid grasp pose annotations for 12K objects from the Objaverse-LVIS dataset. We evaluate ZeroGrasp on the GraspNet-1B benchmark as well as through real-world robot experiments. ZeroGrasp achieves state-of-the-art performance and generalizes to novel real-world objects by leveraging synthetic data.
                </Typography>
              </Grid>
              <Typography className={classes.subtitle}>
                Network Architecture
              </Typography>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.content}>
                The overall architecture is quite similar to our previous work <Link href="#/oct_mae" target="_blank">OctMAE</Link> since it uses the same backbone with Octree encoder and decoder, but we improved it for better shape reconstruction and grasp pose prediction with multi-object encoder and 3D occlusion fields.
              </Typography>
              <Grid item>
                <img
                  className={classes.imageStyle}
                  alt='network_architecture.img'
                  src={require('../../assets/zerograsp/overview.png')}
                />
              </Grid>
              <Typography className={classes.subtitle}>
                ReOcS Dataset
              </Typography>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.content}>
                ReOcS dataset provides 3D shape and object pose annotations, with depth maps generated by learning-based stereo matching. It features 18 unseen objects and is divided into three splits based on the extent of occlusions.
              </Typography>
              <Grid item>
                <video className={classes.videoStyle} autoPlay loop >
                  <source src={require('../../assets/zerograsp/reocs.mp4')} type="video/mp4"/>
                </video>
              </Grid>
              <Typography className={classes.subtitle}>
                ZeroGrasp-11B Dataset
              </Typography>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.content}>
                We created the ZeroGrasp-11B dataset, a large-scale synthetic dataset containing 1 million images, 13K objects, and 11 billion grasps. We design this dataset to train our proposed model and improve its generalizability across a diverse range of objects.
              </Typography>
              <Grid item>
                <video className={classes.videoStyle} autoPlay loop >
                  <source src={require('../../assets/zerograsp/zerograsp-11B.mp4')} type="video/mp4"/>
                </video>
              </Grid>

              <Typography className={classes.subtitle}>
                GraspNet-1B Benchmark
              </Typography>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.content}>
              Our method achieves the state-of-the-art performance on all three splits (seen, similar, and novel) on GraspNet-1B benchmark.
              </Typography>
              <Grid item>
                <video className={classes.videoStyle} autoPlay loop >
                  <source src={require('../../assets/zerograsp/graspnet-1b.mp4')} type="video/mp4"/>
                </video>
              </Grid>
              <Typography className={classes.subtitle}>
                Real-robot Experiments
              </Typography>
              <Divider className={classes.dividerStyle} />
              <Typography className={classes.content}>
              ZeroGrasp achieves a success rate of 75%, which is 19% higher than the baseline method (OCNN) even though training is done only by synthetic data.
              </Typography>
              <Grid item>
                <video className={classes.videoStyle} autoPlay loop >
                  <source src={require('../../assets/zerograsp/real-robot.mp4')} type="video/mp4"/>
                </video>
              </Grid>
              <Typography className={classes.subtitle}>
                Citation
              </Typography>
              <Divider className={classes.dividerStyle} />
              <div className={classes.citationContainerStyle}>
                <Tooltip title="Copy" placement="left" arrow>
                  <IconButton className={classes.citationCopyButtonStyle} onClick={onClickCopyButton} >
                    <FileCopyOutlinedIcon/>
                  </IconButton>
                </Tooltip>
                <div className={classes.citationStyle}>
                  <pre>
                    {citation}
                  </pre>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(ZeroGraspContainer);
